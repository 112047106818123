import './assets/main.css'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { useScreenOrientation } from '@vueuse/core'
import { MotionPlugin } from '@vueuse/motion'
import VueLazyLoad from 'vue3-lazyload'
import App from './App.vue'
import useTelegram from './application/services/useTelegram'
import router from './router'

import { createI18n } from 'vue-i18n'
import './application/services/echo'
import { localeMessages } from './locales/messages'

import * as Sentry from '@sentry/vue'

useScreenOrientation().lockOrientation('portrait-primary').catch(console.warn)

const { ready, expand, setHeaderColor, disableVerticalSwipes, telegramUser } = useTelegram()
const app = createApp(App)

const i18n = createI18n({
  legacy: false,
  locale: 'ru' || telegramUser?.language_code || 'en',
  fallbackLocale: 'en',
  messages: localeMessages
})

const sentryEnv = import.meta.env.VITE_SENTRY_ENV

if (sentryEnv === 'production') {
  const dsn = import.meta.env.VITE_SENTRY_DSN

  Sentry.init({
    app,
    dsn,
    integrations: [],
    environment: sentryEnv
    //   integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', /^https:\/\/stage.tma.greatdev.net\/api/],
    // Session Replay
    //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // trackComponents: ['TournamentTab', 'TournamentsList', 'Tournament', 'TournamentBet']
  })
}

app.use(createPinia()).use(router).use(MotionPlugin).use(i18n).use(VueLazyLoad, {})

app.mount('#app')

ready()
expand()
setHeaderColor('#333a2b')
disableVerticalSwipes()
