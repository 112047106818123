<script setup lang="ts">
import { useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import ComingSoon from '../common/ComingSoon.vue'
import Container from '../common/Container.vue'
import InProgress from '../event/Event.vue'
import Registration from './Registration.vue'
import Results from './Results.vue'

const userEventStore = useGameEventsStore()
const { tournamentRound } = storeToRefs(userEventStore)

const currentEventPhase = computed(() => {
  if (!tournamentRound.value) return ComingSoon

  switch (tournamentRound.value.round_phase) {
    case 'registration':
      return Registration
    case 'tournament':
      return InProgress
    case 'results':
      return Results
    default:
      return Registration
  }
})
</script>

<template>
  <Container>
    <component else :is="currentEventPhase" />
  </Container>
</template>

<style scoped></style>
